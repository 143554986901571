<template>
  <v-app>

    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-12 order-xl-1">
       <div class="d-flex justify-content-between">
         <h3>Coupon</h3>
         <v-btn
                 text
                 @click="addCoupon"
                 outlined
                 class="btn btn-lg btn-add-main"
                 style="background:transparent;"
         >
           <i class="fas fa-plus-circle"></i> Add Coupon
         </v-btn>
       </div>
        <KTPortlet class="mt-2">

          <template v-slot:body>
            <div class="kt-invoice__body">
              <div class="kt-invoice__container">
                <div class="row" v-if="loading && coupons && coupons.length<1">
                  <div class="col-md-12">
                    <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                  </div>
                </div>
                <div class="row" v-else-if="!loading && coupons && coupons.length<1">
                  <div class="col-md-12 text-center">
                    <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                    <h5 class="mt-10">Looks like you have not added any data here. Trying adding some data.</h5>
                  </div>
                </div>

                <b-table
                    v-else
                    hover
                    :items="coupons"
                    id="my-table"
                    :busy.sync="isBusy"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                >
                  <template v-slot:cell(coupon_code)="row">
                    <span class="badge badge-primary">{{ row.item.coupon_code }}</span>
                  </template>
                  <template v-slot:cell(discount_type)="row">
                    <span class="badge badge-primary">{{ row.item.discount_type.toUpperCase() }}</span>
                  </template>
                  <template v-slot:cell(discount_amount)="row">
                    <span
                        v-if="row.item.discount_type=='fixed'"
                    >{{ row.item.currency }}{{ row.item.discount_amount }}</span>
                    <span v-else>{{ row.item.discount_amount }}</span>
                  </template>
                  <template v-slot:cell(is_active)="row">
                    <span>
                      <i
                          :class="(row.item.is_active)?'fa fa-check text-success': 'fa fa-ban text-danger'"
                      ></i>
                    </span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <div class="kt-widget__toolbar">
                      <a
                          href="#"
                          class="btn btn-clean btn-sm btn-icon btn-icon-md"
                          data-toggle="dropdown"
                      >
                        <i class="flaticon-more-1"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                        <ul class="kt-nav">
                          <li class="kt-nav__item">
                            <a href="#" class="kt-nav__link">
                              <i class="kt-nav__link-icon flaticon-edit"></i>
                              <span
                                  class="kt-nav__link-text"
                                  @click="editCouponCode(data.item)"
                              >Edit</span>
                            </a>
                          </li>
                          <li class="kt-nav__item">
                            <a class="kt-nav__link" @click="deleteCoupon(data.item.id)">
                              <i class="kt-nav__link-icon flaticon-delete"></i>
                              <span class="kt-nav__link-text">Delete</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
    <v-dialog scrollable v-model="dialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">Coupon Code</span>
        </v-card-title>
        <v-card-text>
          <form>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field label="Title*" name="title" v-model="coupon.title" required
                                    dense
                                    outlined></v-text-field>
                      <p
                          class="text-danger font-weight-bold"
                          v-if="$v.coupon.title.$error"
                      >{{ validationMessage.title }}</p>
                    </v-col>
                    <v-col cols="4" sm="4" md="4">
                      <v-text-field
                          label="Coupon Code*"
                          name="coupon_code"
                          v-model="coupon_code"
                          required
                          dense
                          outlined
                      ></v-text-field>
                      <p
                          class="text-danger font-weight-bold"
                          v-if="$v.coupon.coupon_code.$error"
                      >{{ validationMessage.coupon_code }}</p>
                    </v-col>

                    <v-col cols="4" sm="4" md="4">
                      <v-select
                          v-model="coupon.discount_type"
                          :items="discountType"
                          label="Discount Type"
                          hint="Discount Type"
                          name="discout_type"
                          dense
                          outlined
                      ></v-select>
                      <p
                          class="text-danger font-weight-bold"
                          v-if="$v.coupon.discount_type.$error"
                      >{{ validationMessage.discount_type }}</p>
                    </v-col>

                    <v-col cols="4" sm="4" md="4">
                      <v-text-field
                          v-model="coupon.discount_amount"
                          label="Discount Amount"
                          hint="Discount Amount"
                          name="discount_amount"
                          type="number"
                          dense
                          outlined
                      ></v-text-field>
                      <p
                          class="text-danger font-weight-bold"
                          v-if="$v.coupon.discount_amount.$error"
                      >{{ validationMessage.discount_amount }}</p>
                    </v-col>


                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                          v-model="coupon.min_checkout_amount"
                          label="Min. Purchase"
                          hint="Minimum Checkout Amount"
                          name="min_checkout_amount"
                          type="number"
                          dense
                          outlined
                      ></v-text-field>
                      <p
                          class="text-danger font-weight-bold"
                          v-if="$v.coupon.min_checkout_amount.$error"
                      >{{ validationMessage.min_checkout_amount }}</p>
                    </v-col>

                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                          v-model="coupon.per_user_units"
                          label="Max usage per user"
                          hint="Number of times single user can use this code"
                          name="per_user_units"
                          type="number"
                          dense
                          outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                          v-model="coupon.total_units"
                          label="Total Qty"
                          hint="Total number of codes available to use"
                          name="total_units"
                          type="number"
                          dense
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                            cols="12"
                            sm="6"
                            md="6"
                    >
                      <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                                  v-model="coupon.validity_start_date"
                                  label="validity Start date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="coupon.validity_start_date"
                                @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                            cols="12"
                            sm="6"
                            md="6"
                    >
                      <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                                  v-model="coupon.validity_end_date"
                                  label="validity End date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="coupon.validity_end_date"
                                @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-select
                          :items="websites"
                          v-model="coupon.website_id"
                          label="Website"
                          item-text="title"
                          item-value="id"
                          type
                          dense
                          outlined
                      ></v-select>
                      <p
                          class="text-danger font-weight-bold"
                          v-if="$v.coupon.website_id.$error"
                      >{{ validationMessage.website_id }}</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                          label="Description*"
                          name="description"
                          v-model="coupon.description"
                          dense
                          outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-col cols="12" sm="12" md="12">
                    <v-switch v-model="coupon.is_active" label="Status"></v-switch>
                  </v-col>
                </v-col>
              </v-row>
            </v-container>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close()">Close</v-btn>
          <v-btn class="btn btn-primary" text @click.prevent="saveOrUpdate()">Save</v-btn>
        </v-card-actions>
        <!-- <pre>{{coupon}}</pre> -->
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import WebsiteService from "@/services/Websites/WebsiteService";
import CouponService from "@/services/coupon/CouponService";

const websiteService = new WebsiteService();
const couponService = new CouponService();

export default {
  components: {
    KTPortlet
  },
  validations: {
    coupon: {
      title: {required},
      coupon_code: {required},
      discount_type: {required},
      discount_amount: {required},
      min_checkout_amount: {required},
      website_id: {required}
    }
  },
  data() {
    return {
      edit: false,
      menu: false,
      loading: true,
      dates: [],
      dialog: false,
      coupon_code: null,
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      coupons: [],
      discountType: ["percentage", "fixed"],
      websites: [],
      coupon: {
        title: null,
        description: null,
        discount_type: "fixed",
        discount_amount: null,
        min_checkout_amount: null,
        coupon_code: null,
        validity_start_date: null,
        validity_end_date: null,
        per_user_units: null,
        total_units: null,
        user_id: null,
        website_id: null,
        is_active: 1
      },
      // coupon:,
      validationMessage: {
        title: "Title Field is required",
        coupon_code: "Coupon Code Field is required",
        discount_type: "Discount Type Field is required",
        min_checkout_amount: "Minimum Checkout Amount Field is required",
        discount_amount: "Discount is required either in percentage or fixed",
        website_id: "Please select website"
      },
      fields: [
        {key: "title", sortable: true},
        {key: "coupon_code", sortable: true},
        {key: "discount_type", sortable: true},
        {key: "discount_amount", sortable: true},
        {key: "discount_amount", sortable: true},
        {key: "website", sortable: true},
        {key: "is_active", label: "Status", sortable: true},
        {key: "action"}
      ]
    };
  },
  computed: {
    formTitle() {
      return this.edit ? "Edit Coupon Code" : "Add Coupon Code";
    }
  },
  mounted() {
    this.getCoupons();
    this.getAllWebsite();
  },
  methods: {
    getAllWebsite() {
      websiteService
          .getAll()
          .then(response => {
            this.websites = response.data.data;
            this.loading = false;
          })
          .catch(error => {
          });
    },
    getCoupons() {
      couponService
          .paginate()
          .then(response => {
            this.coupons = response.data;
            this.loading = false;
          })
          .catch(error => {
          });
    },
    addCoupon() {
      this.dialog = true;
      this.edit = false;
    },
    editCouponCode(item) {
      this.coupon = item;
      this.dialog = true;
      this.edit = true;
      this.dates = [];
      if (
          this.coupon.validity_start_date != null &&
          this.coupon.validity_start_date != undefined
      )
        this.dates.push(this.coupon.validity_start_date);
      if (
          this.coupon.validity_end_date != null &&
          this.coupon.validity_end_date != undefined
      )
        this.dates.push(this.coupon.validity_end_date);
      this.coupon_code = this.coupon.coupon_code;
    },
    close() {
      this.dialog = false;
      this.edit = false;
      this.coupon = {};
    },
    saveOrUpdate() {
      this.$v.coupon.$touch();
      if (this.$v.coupon.$error) {
        setTimeout(() => {
          this.$v.coupon.$reset();
        }, 3000);
      } else {
        this.coupon.user_id = this.websites[0].user_id;
        if (this.edit) {
          this.update();
        } else {
          this.save();
        }
      }
    },

    save() {
      couponService
          .create(this.coupon)
          .then(response => {
            this.$snotify.success("Coupon code saved successfully");
            this.reset();
          })
          .catch(error => {
          });
    },

    update() {
      couponService
          .update(this.coupon.id, this.coupon)
          .then(response => {
            this.$snotify.success("Coupon code saved successfully");
            this.reset();
          })
          .catch(error => {
          });
    },

    reset() {
      this.dialog = false;
      this.edit = false;
      this.coupon = {};
      this.getCoupons();
      this.$v.coupon.$reset();
    },
    deleteCoupon(id) {
      this.$confirm(
          {
            message: `Are you sure?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                couponService.delete(id).then(res => {
                  this.$snotify.success("Coupon code deleted successfully");
                  this.reset();
                });
              }
            }
          }
      )

    }
  },
  watch: {
    coupon_code: function () {
      if (this.coupon_code !== null) {
        this.coupon_code = this.coupon_code.toUpperCase();
        this.coupon.coupon_code = this.coupon_code;
      }
    }
  }
};
</script>
